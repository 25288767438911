export const ACTION_TYPE = {
	SET_CATEGORY_ID: 'SET_CATEGORY_ID',
	SET_SORT_ORDER: 'SET_SORT_ORDER',
	SET_DRAWER_VISIBILITY: 'SET_DRAWER_VISIBILITY',
	SET_SCROLLER: 'SET_SCROLLER',
	SET_APP_LOADING: 'SET_APP_LOADING',
	//
	SET_PRODUCTS: 'SET_PRODUCTS',
	SET_PRODUCTS_LOADING: 'SET_PRODUCTS_LOADING',
	ADD_PRODUCT: 'ADD_PRODUCT',
	DELETE_PRODUCT: 'DELETE_PRODUCT',
	EDIT_PRODUCT: 'EDIT_PRODUCT',
	//
	SET_CATEGORIES: 'SET_CATEGORIES',
	SET_CATEGORIES_LOADING: 'SET_CATEGORIES_LOADING',
	ADD_CATEGORY: 'ADD_CATEGORY',
	DELETE_CATEGORY: 'DELETE_CATEGORY',
	EDIT_CATEGORY: 'EDIT_CATEGORY',
	//
	SET_USER: 'SET_USER',
	SET_USER_AUTH: 'SET_USER_AUTH',
	CLEAR_USER: 'CLEAR_USER',
	DELETE_USER: 'DELETE_USER',
	EDIT_USER: 'EDIT_USER',
	//
	SET_ORDERS: 'SET_ORDERS',
	SET_ORDERS_LOADING: 'SET_ORDERS_LOADING',
	DELETE_ORDER: 'DELETE_ORDER',
	//
	SET_USERS: 'SET_USERS',
	SET_USERS_LOADING: 'SET_USERS_LOADING',
	CLEAR_USERS: 'CLEAR_USERS',
	//
	SET_ROLES: 'SET_ROLES',
	//
	SET_CART: 'SET_CART',
	SET_CART_LOADING: 'SET_CART_LOADING',
	REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
	CLEAR_CART: 'CLEAR_CART',
	//
};
